/** @jsx jsx */
import { jsx, css } from "@emotion/react";
import Page from "../layouts/page";
import fence from "../images/fence.jpg";
import { useForm } from "react-hook-form";
import { useState } from "react";

const contactForm = css`
padding: 40px;

* {
  margin: 0px;
}
`

const imageCss = css`
border-radius: 40px;
`

const missionDiv = css`
margin: 40px;
display: flex;
padding-bottom: 40px;

* {
  align-self: center;
  vertical-align: middle;
}

div {
    padding: 30px;
}
`

const missionHeader = css`
margin: 0px;
padding: 0px;
font-size: 4em;
`

const formStyles = css`
margin-top: 40px;
* {
    display: block;
}

textarea,
select,
input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 15px;
  font-size: 14px;
  background-color: #e7e7e7;
}

textarea {
    font-family: Fredoka, sans-serif;
}

input[type="submit"],
.button {
  position: relative;
  background: #90ABEA;
  color: white;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  margin-top: 20px;
  padding: 20px;
  font-size: 16px;
  letter-spacing: 2px;
  display: block;
  appearance: none;
  border-radius: 4px;
  width: 100%;
  font-weight: 400;
  letter-spacing: 0.5rem;
  transition: 0.3s all;
}

  input[type="submit"]:hover,
button[type="button"]:hover {
  background: #6A7FAF;
  color: white;
  cursor: pointer;
}

label {
  padding-bottom: 3px;
  font-weight: 500;
}

`;

const ContactPage = () => {
    const { register, handleSubmit } = useForm();

  const [ submitted, setSubmitted ] = useState(false);

    return (
        <Page title="Contact Us">
            <div css={contactForm}>
                <h1 css={missionHeader}>Contact Us</h1>
                <div css={missionDiv}>
                    <div>
                    <img css={imageCss} src={fence}/>
                    </div>
                    <div>
                        <h3>We'd love to hear from you! Please fill out the form below and we'll get back to you as soon as possible!</h3>
                        <br/>
                        {submitted ? <p>Thanks for the enquiry! We'll get back to you as soon as possible.</p> : <form css={formStyles} onSubmit={handleSubmit((data) => submit(data, setSubmitted))}>
                            <label>Full name</label>
                            <input {...register("name", {required: true})} placeholder="Daniel Milnes" />
                            <label>Email</label>
                            <input {...register("email", {required: true})} placeholder="daniel@bingus.uk" />
                            <label>Enquiry type</label>
                            <select {...register("category", {required: true})}>
                                <option value="">Enquiry type...</option>
                                <option value="sales">Sales</option>
                                <option value="network">NOC</option>
                                <option value="other">Other</option>
                            </select>
                            <label>About your enquiry</label>
                            <textarea {...register("enquiry", {required: true})} placeholder="Tell us about your enquiry..." />
                            <input type="submit" />
                        </form>}
                    </div>
                </div>
            </div>
        </Page>
    )
}

const submit = (data: {name: string, category: string, enquiry: string}, setSubmitted) => {
  if (data.name == "" || data.category == "" || data.enquiry == "") {
    return;
  }

  fetch("/api/form", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      name: data.name,
      category: data.category,
      enquiry: data.enquiry,
      email: data.email
    })
  })
  .then(resp => {
    setSubmitted(true)
  })
}

export default ContactPage;
